<template>
    <div class="home">
        <div class="banner">
            <div class="video">
              <!-- <iframe class="video-iframe" src="https://www.youtube.com/embed/jsumy3WyKms?si=fXkadCYHL2ptveZq&amp;mute=1&showinfo=0&controls=0&autoplay=1&loop=1&playlist=jsumy3WyKms&disablekb=1" title="YouTube video player" frameborder="0" allowfullscreen></iframe> -->
              <iframe class="video-iframe" :src=currentVideo title="YouTube video player" frameborder="0" allowfullscreen></iframe>
            </div>
            <h1 class="banner-title" :style="bannerTitleVars">Hey There!</h1>
            <h2 class="banner-title" :style="bannerTitleVars">
                My Name is Michael.
            </h2>
        </div>
        <div class="home-content">
            <div class="home-about-excerpt">
                <div class="about-excerpt-headshot-container">
                    <img
                        src="@/assets/headshot_new.jpg"
                        class="about-excerpt-headshot"
                    />
                    <div
                        class="about-excerpt-headshot-backing-1"
                        :style="aboutExcerptVars"
                    ></div>
                    <div
                        class="about-excerpt-headshot-backing-2"
                        :style="aboutExcerptVars"
                    ></div>
                </div>
                <div class="about-excerpt-text">
                    I am a master's student at Purdue University working in computer vision and autonomous robotics. I wanted to take this
                    opportunity to showcase some of the many different projects
                    that I have worked on. It's great to have you here, I
                    hope you enjoy!
                </div>
            </div>
            <div class="home-content-triangle-partition-left home-partition">
                <div class="yes"></div>
            </div>
            <div
                class="home-content-triangle-partition-right home-partition"
            ></div>
            <div class="home-robot-3d-model-container">
                <model-viewer
                    :src="model3D"
                    auto-rotate
                    autoplay
                    camera-controls
                    disable-zoom
                    class="home-robot-3d-model"
                    loading="eager"
                >
                    <div class="button--slide-left">
                        <button @click="toProjects">
                            <span>Explore my Projects!</span>
                        </button>
                    </div>
                </model-viewer>
            </div>
        </div>
    </div>
</template>

<script>
import debounce from "lodash/debounce";

export default {
    name: "Home",
    data() {
        return {
            scrollTemp: 0,
            titleOpacity: 1,
            aboutHeadshotOffset: "0",
            model3D: "eve.glb",
            links: ["https://www.youtube.com/embed/jsumy3WyKms?si=fXkadCYHL2ptveZq", "https://www.youtube.com/embed/gzD_Gn-y34k?si=bApro3wCsDbf3Jhs"],
            currentVideoIndex: 0,
            currentVideo: "https://www.youtube.com/embed/jsumy3WyKms?si=fXkadCYHL2ptveZq&amp;mute=1&showinfo=0&controls=0&autoplay=1&loop=1&playlist=jsumy3WyKms&disablekb=1",
        };
    },
    computed: {
        bannerTitleVars() {
            return {
                "--home-title-opacity": this.titleOpacity,
            };
        },
        aboutExcerptVars() {
            return {
                "--about-headshot-offset": this.aboutHeadshotOffset + "px",
            };
        },
    },
    mounted() {
        setTimeout(this.change3DModel, 10000);
        setTimeout(this.changeVideo, 20000);
    },
    methods: {
        handleScroll() {
            // Any code to be executed when the window is scrolled
            this.isUserScrolling = window.scrollY > 0;
            if (
                window.scrollY.toFixed() >= 100 &&
                window.scrollY.toFixed() <= 300
            ) {
                this.titleOpacity = 1 - (window.scrollY - 100).toFixed() / 200;
            }
            if (
                window.scrollY > window.innerHeight / 2 &&
                window.scrollY < window.innerHeight + 100
            ) {
                // console.log(window.scrollY);
                this.aboutHeadshotOffset =
                    (25 * (window.scrollY - window.innerHeight / 2)) /
                    (window.innerHeight / 2);
            }
        },
        toProjects() {
            this.$router.push({ path: "/projects" });
        },
        change3DModel() {
            if(this.model3D == "eve.glb") {
                this.model3D = "BikeAssembly.glb"
            }
            else if (this.model3D == "BikeAssembly.glb") {
                this.model3D = "ur5.glb";
            } else if (this.model3D == "ur5.glb") {
                this.model3D = "InvertedPendulum.glb";
            } else if (this.model3D == "InvertedPendulum.glb") {
                this.model3D = "eve.glb";
            } else{
                this.model3D = "eve.glb";
            }
        },
        changeVideo() {
            if(this.currentVideoIndex == 0){
                this.currentVideoIndex = 1;
            } else {
                this.currentVideoIndex = 0;
            }

            this.currentVideo = this.links[this.currentVideoIndex] + "&amp;mute=1&showinfo=0&controls=0&autoplay=1&loop=1&playlist=" + this.links[this.currentVideoIndex].split("?")[0].slice(-11) + "&disablekb=1"
        }
    },

    created() {
        this.handleDebouncedScroll = debounce(this.handleScroll, 0);
        window.addEventListener("scroll", this.handleDebouncedScroll);
    },

    beforeDestroy() {
        window.removeEventListener("scroll", this.handleDebouncedScroll);
    },

    watch: {
        model3D() {
            setTimeout(this.change3DModel, 10000);
        },
        currentVideo() {
            setTimeout(this.changeVideo, 20000);
        }
    },
};
</script>

<style lang="scss">
.home {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    --headshot-image-height: 20vh;
    --headshot-image-aspect-ratio: 0.7496;
    --headshot-image-width: calc(
        var(--headshot-image-height) * var(--headshot-image-aspect-ratio)
    );

    @media screen and (min-width: 1260px) {
        --headshot-image-height: 50vh;
        --headshot-image-aspect-ratio: 0.7496;
        --headshot-image-width: calc(
            var(--headshot-image-height) * var(--headshot-image-aspect-ratio)
        );
    }
}
.banner {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: 5em;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  @media screen and (min-width: 768px) {
    height: 56.25vw;
  }
}
.video-iframe {
  position: relative;
  top: 0;
  left: -200%;
  width: 375%;
/*  height: 200%;*/
  height: 100vh;
  filter: brightness(0.3);
  pointer-events: none;
  @media screen and (min-width: 768px) {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.banner-title {
    color: #fff;
    font-weight: 300;
    font-size: 50px;
    z-index: 999;
    opacity: var(--home-title-opacity);
    max-width: 80vw;
}
.home-content {
    position: relative;
    width: 100%;
    background-color: #eeeeee;
}
.home-about-excerpt {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 5%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column-reverse;

    @media screen and (min-width: 1260px) {
        flex-direction: row;
    }
}
.about-excerpt-headshot-container {
    position: relative;
    width: calc(var(--headshot-image-width) + 100px);
    height: calc(var(--headshot-image-height) + 100px);
    max-height: 30vh;
}
.about-excerpt-headshot {
    position: absolute;
    top: 50%;
    left: 50%;
    height: var(--headshot-image-height);
    box-shadow: 0 0 15px rgba(20, 40, 60, 0.4);
    border-radius: 20px;
    transform: translateX(-50%) translateY(-50%); /* translateY(var(--about-headshot-offset))*/
    z-index: 1;
}
.about-excerpt-headshot-backing-1,
.about-excerpt-headshot-backing-2 {
    position: absolute;
    top: 50%;
    left: 50%;
    height: var(--headshot-image-height);
    width: var(--headshot-image-width);
    border-radius: 20px;
    z-index: 0;
}
.about-excerpt-headshot-backing-1 {
    background-color: var(--theme-red);
    transform: translateX(calc(-50% - var(--about-headshot-offset)))
        translateY(calc(-50% + var(--about-headshot-offset)));
}
.about-excerpt-headshot-backing-2 {
    background-color: var(--theme-gray);
    transform: translateX(calc(-50% + var(--about-headshot-offset)))
        translateY(calc(-50% - var(--about-headshot-offset)));
}
.about-excerpt-text {
    font-size: 1em;
    line-height: 2em;
    letter-spacing: 0.075em;
    width: 80vw;
    /*transform: translateY(calc(-1 * var(--about-headshot-offset)));*/
    @media screen and (min-width: 1260px) {
        font-size: 30px;
        width: 50%;
    }
}
.home-partition {
    position: absolute;
    top: 100vh;
    width: 0;
    height: 0;
    z-index: 2;
}
.home-content-triangle-partition-left {
    right: 0;
    z-index: 5;
    border-bottom: 6.75vh solid #24252a;
    border-top: 6.75vh solid transparent;
    border-right: 49.5vw solid #24252a;
    border-left: 49.5vw solid transparent;
    @media screen and (min-width: 768px) {
        border-bottom: 12.5vh solid #24252a;
        border-top: 12.5vh solid transparent;
        border-right: 49.5vw solid #24252a;
        border-left: 49.5vw solid transparent;
    }
}
.home-content-triangle-partition-right {
    left: 0;
    border-bottom: 6.75vh solid #f03a3a;
    z-index: 4;
    border-top: 6.75vh solid transparent;
    border-right: 25vw solid transparent;
    border-left: 25vw solid #f03a3a;
    @media screen and (min-width: 768px) {
        border-bottom: 12.5vh solid #f03a3a;
        border-top: 12.5vh solid transparent;
        border-right: 25vw solid transparent;
        border-left: 25vw solid #f03a3a;
    }
}
.home-robot-3d-model-container {
    position: relative;
    top: -1px;
    bottom: 0;
    &::after {
      position: absolute;
      content: 'Website made from scratch with ❤ by Michael Goldberg';
      color: #fff;
      bottom: 5vh;
      left: 50%;
      transform: translateX(-50%);
      font-size: 12px;
    }
}
.home-robot-3d-model {
    margin-top: 13.5vh;
    width: 100%;
    position: relative;
    --poster-color: #222;
    height: calc(100vh - var(--nav-height));
    background-color: #24252a;
    @media screen and (min-width: 768px) {
        margin-top: 25vh;
    }
}
.button--slide-left {
    --bg-color: #d4900d;
    --color: #fff;
    display: inline-block;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    border-radius: 500px;
    z-index: 1;
    @media screen and (min-width: 768px) {
        top: 0;
    }
}
.button--slide-left button {
    all: unset;
    padding: 10px 20px;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    letter-spacing: 0.075em;
    font-size: 16px;
    border: solid 1px var(--color);
    border-radius: inherit;
    color: var(--color);
    -webkit-text-fill-color: var(--color);
    transition: color, background-color, -webkit-text-fill-color, border-width,
        0.15s ease-in-out;
    @media screen and (min-width: 768px) {
        font-size: 20px;
        padding: 20px 50px;
    }
}
.button--slide-left span {
    mix-blend-mode: difference;
}
.button--slide-left::after {
    content: "";
    background-color: var(--color);
    color: var(--bg-color);
    -webkit-text-fill-color: var(--bg-color);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: inherit;
    z-index: -1;
    clip-path: circle(0% at 50% 50%);
    transition: clip-path 0.5s;
}
.button--slide-left:hover::after {
    clip-path: circle(100%);
}
</style>
