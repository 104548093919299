<template>
  <div id="app">
    <input type="checkbox" class="main-nav-bars" v-if="mobileView" v-model="showNav">
    <main-nav v-if="!mobileView"></main-nav>
    <div class="mobile-nav-container" v-if="showNav">
        <ul class="mobile-nav-content">
            <li class="nav-content-item" @click="updateNavSelection()">
                <router-link
                    to="/"
                    class="mobile-nav-link"
                    :class="{ active: homeActive }"
                >
                    Home
                </router-link>
            </li>
            <li class="nav-content-item" @click="updateNavSelection()">
                <router-link
                    to="/work-experience"
                    class="mobile-nav-link"
                    :class="{ active: workExperienceActive }"
                >
                    Work Experience
                </router-link>
            </li>
            <li class="nav-content-item" @click="updateNavSelection('projects')">
                <router-link
                    to="/projects"
                    class="mobile-nav-link"
                    :class="{ active: projectsActive }"
                >
                    Projects
                </router-link>
            </li>
            <li class="nav-content-item" @click="updateNavSelection('courses')">
                <router-link
                    to="/courses"
                    class="mobile-nav-link"
                    :class="{ active: coursesActive }"
                >
                    Courses
                </router-link>
            </li>
        </ul>
    </div>
    <router-view />
  </div>
</template>

<script type="text/javascript">
import mainNav from "@/components/main-nav.vue";

export default {
  data() {
    return {
      test: window.innerWidth,
      mobileView: window.innerWidth <= 768,
      showNav: false,
      homeActive: true,
      workExperienceActive: false,
      projectsActive: false,
      coursesActive: false,
    }
  },
  beforeMount() {
    window.addEventListener('resize', this.handleView);
    window.addEventListener('resize', this.updateNavSelection);
  },
  mounted() {
    this.updateNavSelection();
  },
  methods: {
    handleView() {
        this.mobileView = window.innerWidth <= 768;
    },
    updateNavSelection() {
        if (this.$route.name == "Home") {
            this.homeActive = true;
            this.workExperienceActive = false;
            this.projectsActive = false;
            this.coursesActive = false;
        } else if (this.$route.name == "Work Experience") {
            this.homeActive = false;
            this.workExperienceActive = true;
            this.projectsActive = false;
            this.coursesActive = false;
        } else if (this.$route.name == "Projects") {
            this.homeActive = false;
            this.workExperienceActive = false;
            this.projectsActive = true;
            this.coursesActive = false;
        } else if (this.$route.name == "Courses") {
            this.homeActive = false;
            this.workExperienceActive = false;
            this.projectsActive = false;
            this.coursesActive = true;
        }
        this.showNav = false;
    },
  },
  components: {
    mainNav
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleView);
    window.removeEventListener('resize', this.updateNavSelection);
  },

  watch: {
      $route() {
          this.updateNavSelection();
      },
  },
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
#app {
  font-family: "Lato", "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  --nav-height: 100px;
  --theme-red: #f03a3a;
  --theme-gray: #222;
  --mobile-pixels: 768px;
}
.main-nav-bars {
    all: unset;
    --bars-container-height: 20px;
    --bars-height: 4px;
    --transition-distance: calc(
        (var(--bars-container-height) - var(--bars-height)) / 2
    );
    cursor: pointer;
    position: relative;
    top: 50px;
    left: 90%;
    transform: translateX(-50%);
    display: block;
    width: 30px;
    height: var(--bars-container-height);
    background-color: transparent;
    z-index: 9999;
    &::before,
    &::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        height: var(--bars-height);
        width: 100%;
        background-color: var(--theme-red);
        transition: 0.1s ease-in-out;
        border-radius: 999px;
    }
    &::before {
        transform: translateY(0);
    }
    &::after {
        transform: translateY(
            calc(var(--bars-container-height) - var(--bars-height))
        );
    }
}
.main-nav-bars:checked::before {
    transform: translateY(var(--transition-distance)) rotate(45deg);
}
.main-nav-bars:checked::after {
    transform: translateY(var(--transition-distance)) rotate(-45deg);
}
.main-nav-bars:checked ~ .main-nav-content {
    display: flex;
    &.nav-content-item {
        background-color: var(--theme-red);
    }
}
.main-nav-bars:checked ~ .main-nav-content .nav-content-item {
    z-index: 9999;
    display: block;
    background-color: #222;
}
.main-nav-bars:checked ~ .main-nav-content .nav-content-item .main-nav-link {
}

.mobile-nav-container {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    transition: background-color 0.25s ease-in-out;
    z-index: 999;
}
.mobile-nav-content {
    list-style: none;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    margin-top: var(--nav-height);
    align-items: center;
    z-index: 999;
}
.nav-content-item {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    display: flex;
    cursor: pointer;
    margin: 2em;
}
.mobile-nav-link {
    position: relative;
    text-decoration: none;
    color: var(--theme-red);
    z-index: 1000;
    font-size: 20px;
    text-transform: uppercase;
    &::after {
        content: "";
        position: absolute;
        bottom: -2px;
        width: 0;
        height: 2px;
        background-color: var(--theme-red);
        left: 0;
        transform-origin: center;
        transition: width 0.15s ease-in-out;
    }
}
.mobile-nav-link:hover::after {
    width: 100%;
}
.active::after {
    width: 100%;
}
</style>
